<header class="clearfix">
    <div *ngIf="showLoader">
        <so-loader [mainpageloader]='true'></so-loader>
    </div>
    <div class="row">
        <div class="col-sm-4">
            <a class="logo-block" href="javascript:void(0);">
                <img src="{{cdnUrl}}assets/images/logo-smal-new.svg" alt="Site Owl logo"
                    class="img-responsive center-block" />
            </a>
            <div *ngIf="isCustomer" class="customer-single">
                <a href="javascript:void(0);">{{user?.customerName === '' ||
                    user?.customerName === null ? user?.user?.email :
                    user?.customerName}}</a>
            </div>

            <div class="search-filter-block" *ngIf="user?.user?.profileCompleted
                &&
                activeCustomers.length> 0 &&
                customerId !== 0 && (isAdministrator || !isCustomerTechnician ||
                !isInviteOnly)">
                <a href="javascript:void(0);" class="search-btn-left"><em class="fas
                        fa-search"></em></a>
                <form class="search-form">
                    <div class="form-group typeahead" id="searchResultBlock">
                        <div class="search-input" [formGroup]="searchForm">
                            <input type="text" class="form-control
                                typeahead-input" id="addFilter"
                                placeholder="{{isOnFloorPlan ? 'Search current Plan...' : 'Search System...'}}"
                                (input)="setLoader()" autocomplete="off" name="search" formControlName="searchText">
                        </div>

                        <div class="typeahead-dropdown list-group search-tab"
                            *ngIf="searchForm.controls['searchText'].value && searchForm.controls['searchText'].value !== ''">
                            <tabset id="searchResultTab" class="search-header">
                                <tab [heading]="'Project Devices and Tasks'" id="projectTab"
                                    *ngIf="(!isOnFloorPlan || (isOnFloorPlan && floorData?.isFromProject)) && !isInviteOnly">
                                    <p class="search-message"
                                        *ngIf="globalSearchData?.projectFloorEquipmentSearchDTOs.length === 0 && !isSearchStart">
                                        No matching Project Devices and Tasks found.</p>
                                    <div class="search-tab-list"
                                        *ngIf="globalSearchData?.projectFloorEquipmentSearchDTOs.length > 0 && !isSearchStart"
                                        [ngStyle]="{'display':globalSearchData['projectFloorEquipmentSearchDTOs']?.length>
                                        0 ? 'block':'none'}">
                                        <div class="fixed-header">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 4%;"></th>
                                                        <th style="width: 30%;" class="text-start">
                                                            Device/Task Name
                                                        </th>
                                                        <th style="width: 22%;" class="text-start">
                                                            Abbreviated Name
                                                        </th>
                                                        <th style="width: 14%;" class="text-start">
                                                            Part Number
                                                        </th>
                                                        <th style="width: 15%;" class="text-start">
                                                            IP Address
                                                        </th>
                                                        <th style="width: 15%;" class="text-start">
                                                            MAC Address
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr (click)="$event.stopPropagation();redirectToOther(model,
                                                    'project')" class="search-group-item cursor-pointer" *ngFor="let model
                                                    of
                                                    globalSearchData['projectFloorEquipmentSearchDTOs']">
                                                        <td class="text-center" style="width: 4%;"> <span [ngClass]="{'operational':
                                                            model.installStatus ===
                                                            'Installed','operational-with':model.installStatus
                                                            ===
                                                            'InProgress','not-working':model.installStatus
                                                            ===
                                                           'Planned'}">
                                                                <em [ngClass]="{'fa-map-marker-alt': model?.type === 'device', 'fa-clipboard-list-check': model?.type === 'task'}"
                                                                    class="far fa-map-marker-alt icon-device-search
                                                                "></em>

                                                            </span></td>
                                                        <td class="text-start" style="width: 30%;">
                                                            <div class="text-break">{{model.name}}</div>
                                                            <span class="floor-info"
                                                                *ngIf="!isOnFloorPlan">{{model.floorName}},
                                                                {{model.buildingName}},
                                                                {{model.projectName}}</span>
                                                        </td>
                                                        <td class="text-start" style="width: 22%;">
                                                            {{model.abbreviatedName || null}} </td>
                                                        <td class="text-start" style="width: 14%;">{{model.partNumber ||
                                                            null}}</td>
                                                        <td class="text-start" style="width: 15%;"> {{model.ipAddress ||
                                                            null}} </td>
                                                        <td class="text-start" style="width: 15%;"> {{model.macAddress
                                                            || null}} </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <span class="search-btn" *ngIf="isSearchStart"><em class="fa
                                        fa-spinner fa-animated"></em></span>
                                </tab>
                                <tab [heading]="'Live Site Devices'" id="siteTab"
                                    *ngIf="(!isOnFloorPlan || (isOnFloorPlan && (!floorData?.isFromProject || isLiveDevicesAvailable))) && isLiveSystemAccessForCustomer && !isCustomerTechnician">
                                    <p class="search-message"
                                        *ngIf="globalSearchData?.floorEquipmentSearchDTOs.length === 0 && !isSearchStart">
                                        No matching Live Site Devices found.</p>
                                    <div class="search-tab-list"
                                        *ngIf="globalSearchData?.floorEquipmentSearchDTOs.length > 0 && !isSearchStart"
                                        [ngStyle]="{'display':globalSearchData['floorEquipmentSearchDTOs']?.length>
                                        0 ? 'block':'none'}">
                                        <div class="fixed-header">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 4%;"></th>
                                                        <th style="width: 30%;" class="text-start">
                                                            Device Name
                                                        </th>
                                                        <th style="width: 22%;" class="text-start">
                                                            Abbreviated Name
                                                        </th>
                                                        <th style="width: 14%;" class="text-start">
                                                            Part Number
                                                        </th>
                                                        <th style="width: 15%;" class="text-start">
                                                            IP Address
                                                        </th>
                                                        <th style="width: 15%;" class="text-start">
                                                            MAC Address
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr (click)="$event.stopPropagation();redirectToOther(model,
                                                'site')" class="search-group-item cursor-pointer" *ngFor="let model of
                                                globalSearchData['floorEquipmentSearchDTOs']">
                                                        <td class="text-center" style="width: 4%;">

                                                            <span class="icon-device-search" [ngClass]="{'operational':
                                                        model.status ===
                                                        'Operational','operational-with':model.status
                                                        ===
                                                        'OperationalWithIssue','not-working':model.status
                                                        ===
                                                        'NotWorking'}"><em class="far
                                                            fa-map-marker-alt icon-device-search"></em>
                                                            </span>
                                                        </td>
                                                        <td class="text-start" style="width: 30%;">
                                                            <div class="text-break">{{model.name}}</div>
                                                            <span class="floor-info"
                                                                *ngIf="!isOnFloorPlan">{{model.floorName}},
                                                                {{model.buildingName}},
                                                                {{model.siteName}}</span>
                                                        </td>
                                                        <td class="text-start" style="width: 22%;">
                                                            {{model.abbreviatedName || null}} </td>
                                                        <td class="text-start" style="width: 14%;">{{model.partNumber ||
                                                            null}}</td>
                                                        <td class="text-start" style="width: 15%;"> {{model.ipAddress ||
                                                            null}} </td>
                                                        <td class="text-start" style="width: 15%;"> {{model.macAddress
                                                            || null}} </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <span class="search-btn" *ngIf="isSearchStart"><em class="fa
                                        fa-spinner fa-animated"></em></span>
                                </tab>
                                <tab [heading]="'Service Tickets'" id="ticketTab"
                                    *ngIf="(!isOnFloorPlan || (isOnFloorPlan && !floorData?.isFromProject)) && isTicketAccessible && isLiveSystemAccessForCustomer && !isCustomerTechnician">
                                    <p class="search-message"
                                        *ngIf="globalSearchData?.serviceTicketSearchDTOs.length === 0 && !isSearchStart">
                                        No matching Service Tickets found.</p>
                                    <div class="search-tab-list"
                                        *ngIf="globalSearchData?.serviceTicketSearchDTOs.length > 0 && !isSearchStart"
                                        [ngStyle]="{'display':globalSearchData['serviceTicketSearchDTOs']?.length>
                                        0 ? 'block':'none'}">
                                        <div class="fixed-header">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 4%;"></th>
                                                        <th style="width: 10%;" class="text-start">
                                                            Ticket #
                                                        </th>
                                                        <th style="width: 46%;" class="text-start">
                                                            Subject
                                                        </th>
                                                        <th style="width: 20%;" class="text-start">
                                                            Priority
                                                        </th>
                                                        <th style="width: 20%;" class="text-start">
                                                            Date Due
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr (click)="redirectToOther(model,
                                                'ticket')" class="search-group-item cursor-pointer" *ngFor="let model
                                                of globalSearchData['serviceTicketSearchDTOs']">
                                                        <td class="text-center" style="width: 4%;">
                                                            <span class="icon-device-search" [ngClass]="{'operational':
                                                        model.status ===
                                                        'Closed','operational-with':model.status
                                                        === 'In Progress','not-working':model.status ==='Open'}"><em
                                                                    class="fas fa-tag"></em></span>
                                                        </td>
                                                        <td class="text-start" style="width: 10%;"> {{model.id}} </td>

                                                        <td class="text-start" style="width: 46%;">
                                                            <div class="text-break"> {{model.subject}}</div>
                                                            <span class="floor-info"
                                                                *ngIf="!isOnFloorPlan">{{model.floorName}},
                                                                {{model.buildingName}},
                                                                {{model.siteName}}</span>
                                                        </td>
                                                        <td class="text-start" style="width: 20%;"> {{model.priority}}
                                                        </td>
                                                        <td class="text-start" style="width: 20%;">{{model.dueDate ||
                                                            null}}</td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <span class="search-btn" *ngIf="isSearchStart"><em class="fa
                                        fa-spinner fa-animated"></em></span>
                                </tab>
                            </tabset>

                        </div>

                    </div>
                </form>
            </div>
        </div>
        <div class="col-sm-4 text-center">
            <div class="global-dropdown" *ngIf="(user?.user?.profileCompleted &&
                activeCustomers.length> 1) || isUserProvider">
                <div #user (click)="customerChanged($event)" class="dropdown" [autoClose]="true" dropdown
                    *ngIf="!isCustomer">
                    <a title="{{dashboardName}}" (click)="selectCustomer(null,'header')" class="home-globe"
                        href="javascript:void(0);">
                        <em *ngIf="!isUserProvider" id="global-dashboard" class="fas fa-globe-americas"></em>
                        <em *ngIf="isUserProvider" id="global-dashboard" class="fa-brands fa-fort-awesome"></em>
                    </a>
                    <a href (click)="false" dropdownToggle class="btn-dropdown
                        multiple
                        customer-drop">
                        <span class="dropdown-title" title="  {{customerName || dashboardName }}">
                            {{customerName || dashboardName }}
                        </span>
                        <span>
                            <em class="fas fa-caret-down" *ngIf="activeCustomers.length > 0"></em>
                        </span>
                    </a>
                    <ul *dropdownMenu class="dropdown-menu">
                        <li class="global-list" *ngIf="customerId && customerId > 0" (click)="selectCustomer(null)">
                            <a href (click)="false">
                                <em *ngIf="!isUserProvider" class="fas fa-globe-americas"></em>
                                <em *ngIf="isUserProvider" class="fa-brands fa-fort-awesome"></em> {{dashboardName}}

                            </a>
                        </li>
                        <li *ngIf="showSearch">
                            <span class="customer-search-panel d-flex align-items-center">
                                <em class="fas fa-search customer-search-icon"></em>
                                <input class="form-control customer-search" type="text" name="search"
                                    [(ngModel)]="searchCustomerText" autocomplete="off"
                                    (click)="$event.stopPropagation();" placeholder="Search Account">
                            </span>
                        </li>
                        <li class="recent-customers"
                            *ngIf="showSearch && recentCustomerList && recentCustomerList.length > 0">
                            <div>
                                <div *ngFor="let
                            customerRec of recentCustomerList |
                            customerFilter:searchCustomerText:true:true;index as i">
                                    <h2 *ngIf="i===0">Recent Accounts</h2>
                                </div>
                                <ul class="recent-customer-list">
                                    <li *ngFor="let
                            customerRec of recentCustomerList |
                            customerFilter:searchCustomerText:true:true" (click)="selectCustomer(customerRec)">
                                        <a href="javascript:void(0);">{{customerRec?.name}}</a>
                                </ul>
                            </div>
                        </li>
                        <div *ngIf="showSearch">
                            <li [ngClass]="{ 'active' : customer.id === customerId}" *ngFor="let
                            customer of activeRemainCustomer |
                            customerFilter:searchCustomerText:true" (click)="selectCustomer(customer)">
                                <a href="javascript:void(0);">{{customer?.name}}</a>
                            </li>
                        </div>
                        <div *ngIf="!showSearch">
                            <li [ngClass]=" { 'active' : customer.id===customerId}" *ngFor="let
                            customer of activeCustomers |
                            customerFilter:searchCustomerText:true" (click)="selectCustomer(customer)">
                                <a href="javascript:void(0);">{{customer?.name}}</a>
                            </li>
                        </div>
                    </ul>
                </div>
            </div>
            <div class="customer-name" *ngIf="!isUserProvider && user?.user?.profileCompleted
                &&
                activeCustomers.length === 1">
                <span>{{activeCustomers[0].name}}</span>
            </div>
        </div>

        <div class="col-sm-4">
            <div class="user-dropdown">
                <div class="dropdown" dropdown>
                    <span class="manage-user"
                        *ngIf="user?.user?.providerId &&
                        user?.user?.providerRole?.name !==
                        null && (user?.user?.providerRole?.name === staffMember || user?.user?.providerRole?.name === providerTech)"
                        title="{{user?.user?.providerName}}">{{user?.user?.providerName}}</span>
                    <a (click)="redirectToProvider($event)" class="manage-user
                        provider-name" title="{{user?.user?.providerName}}"
                        *ngIf="user?.user?.providerId &&
                        user?.user?.providerRole?.name !==
                        null && (user?.user?.providerRole?.name !== staffMember && user?.user?.providerRole?.name !== providerTech)" href="javascript:void(0)"
                        [ngClass]="{'disable-link':!user?.user?.profileCompleted}"
                        title="{{user?.user?.providerName}}">{{user?.user?.providerName}}</a>
                    <a href="javascript:void(0);" title="{{user?.user?.name === '' ||
                        user?.user?.name
                        === null ?
                        user?.user?.email : user?.user?.name}}" (click)="false" dropdownToggle
                        class="btn-dropdown user-profile">
                        <em class="profile-img">
                            <img alt="User" soImgOrientation [index]="0" [apply]="null"
                                [parent]="'equipment-thumb-img card'" [src]="thumnailPicture ||
                                user?.user?.thumbnailAwsImageURL ||
                                (cdnUrl + 'assets/images/user-image.png')" class="img-responsive">
                        </em>
                        <span>
                            <em class="fas fa-caret-down" (click)="showDp()"></em>
                        </span>
                    </a>
                    <ul *dropdownMenu class="dropdown-menu" id="show-sub-menu">
                        <li class="user-name">

                            {{user?.user?.name}}
                        </li>
                        <li class="user-email">
                            {{user?.user?.email }}
                        </li>
                        <li class="seprator"></li>
                        <li>
                            <a (click)="userProfileRedirect()">My Profile</a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" (click)="logout()">Log out</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>