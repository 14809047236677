import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'so-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent implements OnInit {

  @Input() pageloader!: boolean
  @Input() tableloader!: boolean
  @Input() smallloader!: boolean
  @Input() mainpageloader!: boolean
  @Input() xsloader!: boolean
  @Input() downloadloader!: boolean
  @Input() fullpageloader!: boolean
  @Input() pixieLoader!: boolean
  @Input() whLoader!: boolean
  popupLoader = false;


  ngOnInit(): void {
    this.popupLoader = false;
    const bodyClass = document.body.classList;
    if (bodyClass.contains('modal-open') && !this.pixieLoader && !this.downloadloader && !this.xsloader && !this.smallloader) {
      this.popupLoader = true;
    }
  }
}