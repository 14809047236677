/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonEnableDisableService, MessageService, CustomerService, ListService, Roles, Utilities, RoleType, ProjectRole, SiteRole, AuthService, CommonService } from '@SiteOwl/core';
import { Component, EventEmitter, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-add-provider-user-to-customer',
  templateUrl: './add-provider-user-to-customer.component.html',
  styleUrls: ['./add-provider-user-to-customer.component.scss'],
})
export class AddProviderUserToCustomerComponent implements OnInit {
  selectedProjectRole: any;
  projectRole: any = ProjectRole
  selectedRole: any = ProjectRole[2]
  siteRole = SiteRole
  customerDetails: any;
  canDisplay = true
  canAdminDisplay = false
  isSiteRole!: boolean;
  userDetail: any;
  roles = {
    amt: [],
    project: []
  }

  providerRole: any;
  providerResult: any;
  providerDetail: any = null;
  customerList: any[] = [];
  userSiteRoles: any = [];
  userProjectRoles: any = [];
  providerId: any;
  user: any;
  selectedSiteRole: any;
  isLiveSystemAccessForCustomer: any = false;
  adminRole: any

  customerForm!: FormGroup<any>;
  disabled = false;
  ShowFilter = true;
  limitSelection = false;
  cities: any = [];
  selectedItems: any = [];
  dropdownSettings: any = {};
  remainCustomerList: any = []
  newCustomerToAdd: any = []
  showRoles = false;
  public event: EventEmitter<any> = new EventEmitter();
  constructor(private authService: AuthService, public bsModalRef: BsModalRef,
    public btnService: ButtonEnableDisableService, private messageService: MessageService,
    private sanitizer: DomSanitizer, private customerService: CustomerService, private listService: ListService,
    private fb: FormBuilder, private commonService: CommonService
  ) { }

  ngOnInit() {
    const isLiveSystemAccessForCustomer: any = this.authService.getUserData().isLiveSystemAccessForProvider;
    this.isLiveSystemAccessForCustomer = isLiveSystemAccessForCustomer;
    if (this.providerRole === '') {
      if (this.authService.getUserKeyData().userRole === Roles.Administrator) {
        this.isLiveSystemAccessForCustomer = true;
      }
    }
    this.remainCustomerList = [];
    if (this.authService.getUserKeyData().userRole !== Roles.Administrator) {
      this.customerList.forEach(element => {
        this.remainCustomerList.push({
          id: element.customer.id,
          text: element.customer.name,
          customerDetails: element
        })
      });
    } else {
      this.customerList.forEach(element => {
        this.remainCustomerList.push({
          id: element.id,
          text: element.name,
          customerDetails: element
        })
      });
    }

    this.user = this.authService.getUserData();
    this.providerId = this.user.providerId;
    this.selectedItems = [];
    this.newCustomerToAdd = [];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'text',
      selectAllText: 'Select All',
      unSelectAllText: 'Select All',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      clearSearchFilter: true,
      noFilteredDataAvailablePlaceholderText: 'No matching Accounts found.',
      searchPlaceholderText: 'Search Accounts',
      maxHeight: 262,
      enableCheckAll: this.authService.getUserKeyData().userRole === Roles.Administrator ? false : true
    };


    this.getRoles();
    this.getProjectRoles();
    this.createForm();
    this.getAdminRole()

  }
  createForm() {
    this.customerForm = this.fb.group({
      customer: ['', [Validators.required]],
      isAdmin: [false],
    })
  }
  private getRoles() {
    this.listService.getCustomerSpecificRoleList().subscribe({
      next: (result: any) => {
        this.roles.amt = result;
        this.roles.amt = Utilities.seperateRoleDescription(this.roles.amt)
        this.siteRole = this.roles.amt;
        this.userSiteRoles = _.cloneDeep(this.siteRole)
        if (this.userSiteRoles.filter((e: any) => e.name === RoleType.TECHNICIAN).length > 0) {
          this.userSiteRoles.filter((e: any) => e.name === RoleType.TECHNICIAN)[0].isDefaultSelected = true;
          this.selectedSiteRole = this.userSiteRoles.filter((e: any) => e.name === RoleType.TECHNICIAN)[0];
        } else {
          this.userSiteRoles[0].isDefaultSelected = true;
          this.selectedSiteRole = this.userSiteRoles[0];
        }
      }
    });
  }
  private getProjectRoles() {
    this.listService.getCustomerSpecificProjectRoleList().subscribe({
      next: (result: any) => {
        this.roles.project = result;
        this.roles.project = Utilities.seperateRoleDescription(this.roles.project)
        this.projectRole = this.roles.project;
        this.userProjectRoles = _.cloneDeep(this.roles.project)
        if (this.userProjectRoles.filter((e: any) => e.name === RoleType.TECHNICIAN).length > 0) {
          this.userProjectRoles.filter((e: any) => e.name === RoleType.TECHNICIAN)[0].isDefaultSelected = true;
          this.selectedProjectRole = this.userProjectRoles.filter((e: any) => e.name === RoleType.TECHNICIAN)[0];
        } else {
          this.userProjectRoles[0].isDefaultSelected = true;
          this.selectedProjectRole = this.userProjectRoles[0];
        }
      }
    });
  }
  onItemChange(role: any, isSite: any) {
    if (isSite) {
      this.userSiteRoles.forEach((element: any) => {
        element.isDefaultSelected = false;
      });
    } else {
      this.userProjectRoles.forEach((element: any) => {
        element.isDefaultSelected = false;
      });
    }
    role.isDefaultSelected = true
    this.selectedRole = null;
    this.selectedRole = role
    if (isSite) {
      this.selectedSiteRole = role;
    } else {
      this.selectedProjectRole = role;
    }
  }
  addUserToCustomer() {
    if (this.customerForm.invalid || !this.selectedSiteRole || !this.selectedProjectRole) {
      return;
    }
    this.btnService.disable()
    let selectRole;
    if (!this.isLiveSystemAccessForCustomer) {
      let keyForRole = this.selectedProjectRole.roleKey.split('_')[1];
      if (keyForRole === 'Lead Technician') {
        keyForRole = 'Agent';
      }
      selectRole = this.roles.amt.find((x: any) => x.roleKey.includes(keyForRole));
    } else {
      selectRole = this.roles.amt.find((x: any) => x.roleKey === this.selectedSiteRole.roleKey);
    }
    const selectProjectRole = this.roles.project.find((x: any) => x.roleKey === this.selectedProjectRole.roleKey)
    const user = {
      customerIds: this.newCustomerToAdd,
      email: this.userDetail.email,
      role: selectRole,
      projectRole: selectProjectRole,
      isAdmin: this.customerForm.controls['isAdmin'].value,
      userId: this.userDetail.id
    }
    this.customerService.addMultipleCustomerToUser(user).subscribe({
      next: (res: any) => {
        delete this.customerDetails.amtRole
        this.btnService.enable()
        this.customerDetails.role = user.role;
        this.customerDetails.projectRole = user.projectRole;
        this.customerDetails.emailReport = 0;
        this.event.emit(res.customerUserDTOs);
        this.bsModalRef.hide();
      }
    })
  }
  closeModal() {
    this.bsModalRef.hide();
  }
  getValue(title: any) {
    return this.sanitizer.bypassSecurityTrustHtml(title);
  }
  ngOnDestroy() {
    this.btnService.enable();
    this.customerDetails = null;
    this.selectedSiteRole = null;
    this.selectedProjectRole = null;
  }
  toggleAdmin(evt: any) {
    this.customerForm.controls['isAdmin'].setValue(evt.target.checked);
    this.userProjectRoles.forEach((element: any) => {
      if (element.roleKey === 'project_Manager') {
        element.isDefaultSelected = true;
      } else {
        element.isDefaultSelected = false;
      }
    });
    this.userSiteRoles.forEach((elementSite: any) => {
      if (elementSite.roleKey === 'amt_Manager') {
        elementSite.isDefaultSelected = true;
      } else {
        elementSite.isDefaultSelected = false;
      }
    });
    this.selectedSiteRole = this.roles.amt.filter((e: any) => e.roleKey === 'amt_Manager')[0];
    this.selectedProjectRole = this.roles.project.filter((e: any) => e.roleKey === 'project_Manager')[0];

  }

  getAdminRole() {
    this.adminRole = this.commonService.getLocalStorageObject('adminRole');
    if (this.adminRole === undefined || this.adminRole === null) {
      this.listService.getAdminSpecificRoleList().subscribe({
        next: (r: any) => {
          this.adminRole = Utilities.seperateRoleDescription(r);
          this.commonService.setLocalStorageObject('adminRole', this.adminRole);
        }
      })
    }
  }

  onItemSelectController(e: any) {
    if (this.remainCustomerList.filter((x: any) => x.id === e.id).length > 0) {
      this.customerDetails = this.remainCustomerList.filter((x: any) => x.id === e.id)[0]['customerDetails'];
      if (this.customerDetails.customer && this.customerDetails.customer.id) {
        this.newCustomerToAdd.push(this.customerDetails.customer.id);
      } else {
        this.newCustomerToAdd.push(this.customerDetails.id);
      }
    }
    if (this.newCustomerToAdd.length > 0) {
      this.canDisplay = true;
    } else {
      this.canDisplay = false;
    }
    this.checkPartiallyChecked();
  }
  onSelectAllController(e: any) {
    this.newCustomerToAdd = [];
    e.forEach((element: any) => {
      this.newCustomerToAdd.push(element.id);
    });
    if (!this.showRoles) {
      this.customerDetails = this.remainCustomerList[0]['customerDetails'];
    }
    if (this.newCustomerToAdd.length > 0) {
      this.canDisplay = true;
    } else {
      this.canDisplay = false;
    }
    this.checkPartiallyChecked();
  }

  onItemDeSelectController(e: any) {
    if (this.remainCustomerList.filter((x: any) => x.id === e.id).length > 0) {
      const index = this.newCustomerToAdd.indexOf(e.id);
      if (index > -1) {
        this.newCustomerToAdd.splice(index, 1); // 2nd parameter means remove one item only
      }
      if (!this.showRoles) {
        this.customerDetails = this.remainCustomerList[0]['customerDetails'];
      }
      this.canDisplay = true;
    }
    this.checkPartiallyChecked();
  }
  onDeSelectAllController() {
    this.newCustomerToAdd = [];
    this.canDisplay = true;
    this.checkPartiallyChecked();
  }
  checkPartiallyChecked() {
    const selectAll: any = document.querySelector('.item1');
    if (selectAll && selectAll.children && selectAll.children.length > 0) {
      selectAll.children[0].classList.remove('uncheckall');
    }
    if (this.newCustomerToAdd.length > 0 && this.newCustomerToAdd.length < this.remainCustomerList.length) {
      const selectAll: any = document.querySelector('.item1');
      if (selectAll && selectAll.children && selectAll.children.length > 0) {
        selectAll.children[0].classList.add('uncheckall');
      }
    }
  }
}

