import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FloorDataTransferService {

  private floorData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private isFavoriteDeviceSelected: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  public isOnFloorPlan = false;
  public isFavDeviceSelected = false;

  constructor() { }
  updateData(data: any): void {
    if (data !== null) {
      this.isOnFloorPlan = true;
    } else {
      this.isOnFloorPlan = false;
    }
    this.floorData.next(data);
  }
  getData(): Observable<any> {
    return this.floorData;
  }

  updateDeviceSelection(isFav = false): void {
    if (isFav) {
      this.isFavDeviceSelected = true
    } else {
      this.isFavDeviceSelected = false
    }
    this.isFavoriteDeviceSelected.next(this.isFavDeviceSelected);
  }
}
