/* eslint-disable @typescript-eslint/no-explicit-any */
import { AccessLevelsWithRoleDetails, ProjectStage, ListService, ProjectService, CustomerService, MessageService, ButtonEnableDisableService, UserService, Roles, Utilities, AuthService } from '@SiteOwl/core';
import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'so-user-project-access',
  templateUrl: './user-project-access.component.html',
  styleUrls: ['./user-project-access.component.scss'],
})
export class UserProjectAccessComponent implements OnInit, OnDestroy {

  isEditMode = false;
  isProjectRole!: boolean
  isProvider!: boolean
  nullValue: any = null;
  providerDetail: any;
  providers!: any[];
  users!: any[];
  customers: any[] = []
  roles = {
    amt: [],
    project: []
  };
  currentUser: any;
  userCount!: number;
  isSelected = true
  tempProviderProjectName!: string;
  tempProviderAMTName!: string
  previouslyCustomerId!: number;
  accesslevelAscending: any;
  userDetails: any;
  resourceForm!: FormGroup<any>;
  projects: any = [];

  selectedRole: any = AccessLevelsWithRoleDetails[2]
  isFormSubmitted!: boolean;
  noCuatomers!: boolean;
  isAllManager!: boolean;
  canShow = false
  providerId: any;
  user: any;
  selectedCustomerId: any = false;
  projectDesign = ProjectStage.Design;
  projectInstallation = ProjectStage.Installation;
  totalProjects!: number;
  selectedAllProjectsCount!: number;
  selectedDesignProjectCount!: number;
  selectedInstallProjectCount!: number;
  totalDesignProjectCount!: number;
  totalInstallProjectCount!: number;
  selectedAllProjects = false;
  isSelectedAllDesignProjects = false;
  isSelectedAllInstallProjects = false;
  projectLoad!: boolean;
  customerWillSelected: any;
  selectedCustomerInfo: any;
  projectAvailableForCustomer: any;
  pageLoader = true;
  customerSelectedName: any;
  loggedInUserCustomerList: any;
  public event: EventEmitter<any> = new EventEmitter();
  constructor(
    public bsModalRef: BsModalRef,
    private projectService: ProjectService,
    private customerService: CustomerService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private authService: AuthService,
    public btnService: ButtonEnableDisableService,
    private listService: ListService,
    private userService: UserService
  ) { }
  ngOnInit(): void {
    this.user = this.authService.getUserData();
    this.createForm();
    if (this.authService.getUserKeyData().userRole === Roles.Administrator) {
      this.getAllCustomers();
    } else {
      this.getAllCustomersByUser();
    }
    this.currentUser = this.authService.getUserKeyData();
  }

  createForm() {
    this.resourceForm = this.fb.group({
      customer: [null, Validators.required],
    })
  }
  getCustomers() {
    this.listService.getProviderCustomerById(this.providerId).subscribe({
      next: (res: any) => {
        let providerCustomers = null;
        providerCustomers = res;
        this.setPopup(providerCustomers);
        this.canShow = true;
      }, error: () => {
        this.pageLoader = false;
      }
    })
  }
  getAllCustomers() {
    this.userService.getActiveCustomers().subscribe({
      next: (result: any) => {
        this.setPopup(result);
        this.canShow = true;
      }
    });
  }
  getAllCustomersByUser() {
    this.setPopup(this.loggedInUserCustomerList);
    this.canShow = true;
  }
  setPopup(customers: any) {
    this.customers = [];
    this.userDetails.customerUserList.forEach((customer: any) => {
      if (customer.projectRole.name !== 'Manager') {
        customer['customerName'] = customer?.customer?.name;
        this.customers.push(customer);
      }
    });
    if (this.customers.length > 0 && this.authService.getUserKeyData().userRole !== Roles.Administrator) {
      this.customers.forEach((tempCustomer, index) => {
        if (customers.filter((x: any) => x.customer?.id === tempCustomer.customer.id).length === 0 || customers.filter((x: any) => x.customer?.id === tempCustomer.customer.id && x.projectRole.name !== Roles.Manager).length > 0) {
          this.customers.splice(index, 1)
        }
      })
    }
    this.customers = _.sortBy(this.customers, [function (a) {
      return a.customer?.name.toLowerCase();
    }]);
    this.selectedCustomerInfo = this.authService.getCustomerId().customerId;
    if (this.selectedCustomerInfo > 0 && this.customerWillSelected) {
      const customer = this.customers.filter(e => e.customer?.id === this.selectedCustomerInfo);
      if (this.userDetails.customerUserList.filter((e: any) => e.customer?.id === this.selectedCustomerInfo).length > 0) {
        this.customerSelectedName = this.userDetails.customerUserList.filter((e: any) => e.customer.id === this.selectedCustomerInfo)[0].customer.name;
      }
      this.selectedCustomerId = true;
      if (customer.length > 0) {
        if (this.resourceForm) {
          this.resourceForm.controls['customer'].setValue(customer[0]);
        }
        this.customerSelected();
      } else {
        this.pageLoader = false;
      }
    } else {
      this.selectedCustomerId = false;
      this.pageLoader = false;
    }
    if (this.userDetails.customerUserList.length > 0 && this.userDetails.customerUserList.every((cust: any) => cust.projectRole.name === 'Manager')) {
      this.isAllManager = true
      this.pageLoader = false;

    } else if (this.customers.length === 0) {
      this.noCuatomers = true
      this.pageLoader = false;
    }
  }
  onItemChange(role: any) {
    this.accesslevelAscending.forEach((element: any) => {
      element.isDefaultSelected = false
      if (element.id === role.id) {
        element.isDefaultSelected = true
      }
    });
    role.isDefaultSelected = true;
    this.selectedRole = null;
    this.selectedRole = role
  }
  customerSelected() {
    this.projects = [];
    this.projectLoad = true;
    if (this.resourceForm.controls['customer'].value === null) {
      return;
    }
    this.customerService.getCustomerProjects(this.resourceForm.controls['customer'].value.customer.id, this.userDetails.userId).subscribe({
      next: (result: any) => {
        const tempProjects: any = result['projects'];
        this.projectAvailableForCustomer = result['projectAvailable'];
        this.projects = _.cloneDeep(tempProjects);
        this.totalProjects = this.projects.length;
        this.selectedAllProjects = false;
        this.isSelectedAllDesignProjects = false;
        this.isSelectedAllInstallProjects = false;
        this.selectedAllProjectsCount = 0;
        this.selectedDesignProjectCount = 0;
        this.selectedInstallProjectCount = 0;
        this.totalDesignProjectCount = 0;
        this.totalInstallProjectCount = 0;
        this.projects.forEach((element: any) => {
          element.name = _.escape(element.name);
          element.isSelectedProject = false;
          if (element.stage === ProjectStage.Design) {
            this.totalDesignProjectCount += 1;
          } else {
            this.totalInstallProjectCount += 1;
          }
        })
        this.projectLoad = false;
        this.pageLoader = false;
      }, error: () => {
        this.pageLoader = false;
      }
    })

  }


  closeModal() {
    this.event.emit('Cancel')
    this.bsModalRef.hide();

  }
  addUserToProject() {
    this.isFormSubmitted = true;
    if (this.resourceForm.invalid || !this.selectedRole) {
      return;
    }
    this.btnService.disable()
    const projectIds: any = [];
    this.projects.filter((e: any) => e.isSelectedProject).forEach((element: any) => {
      projectIds.push(element.id);
    })
    const resource = {
      permissionLevelDTO: {
        emails: [this.userDetails.email],
      },
      projectIds: projectIds,
      customerId: this.resourceForm.controls['customer'].value.customer.id,
      role: this.resourceForm.controls['customer'].value.role,
      projectRole: this.resourceForm.controls['customer'].value.projectRole
    }
    this.customerService.addProjectToCustomer(resource).subscribe(result => {
      this.btnService.enable();
      this.event.emit(result);
      this.bsModalRef.hide();
      let saveUser = this.userDetails.name;
      if (saveUser === null || saveUser === undefined) {
        saveUser = this.userDetails.email
      }
      this.messageService.successMessage(`User: ${saveUser}`, 'Added Successfully')

    })
  }
  ngOnDestroy() {
    this.btnService.enable();
  }
  selectAllProjects() {
    this.selectedAllProjects = !this.selectedAllProjects;
    this.isSelectedAllDesignProjects = this.selectedAllProjects;
    this.isSelectedAllInstallProjects = this.selectedAllProjects;
    this.projects.forEach((element: any) => {
      element.isSelectedProject = this.selectedAllProjects;
    });
    this.selectedAllProjectsCount = this.projects.filter((e: any) => e.isSelectedProject).length;
    this.selectedDesignProjectCount = this.projects.filter((e: any) => e.isSelectedProject && e.stage === this.projectDesign).length;
    this.selectedInstallProjectCount = this.projects.filter((e: any) => e.isSelectedProject && e.stage === this.projectInstallation).length;
  }
  selectAllDesignProjects() {
    this.isSelectedAllDesignProjects = !this.isSelectedAllDesignProjects;
    if (this.selectedAllProjects) {
      if (!this.isSelectedAllDesignProjects) {
        this.selectedAllProjects = false;
      }
    }
    this.projects.forEach((element: any) => {
      if (element.stage === this.projectDesign) {
        element.isSelectedProject = this.isSelectedAllDesignProjects;
      }
    });
    this.selectedDesignProjectCount = this.projects.filter((e: any) => e.isSelectedProject && e.stage === this.projectDesign).length;
    this.checkAllProjectSelected();
  }
  selectAllInstallProjects() {
    this.isSelectedAllInstallProjects = !this.isSelectedAllInstallProjects;
    if (this.selectedAllProjects) {
      if (!this.isSelectedAllDesignProjects) {
        this.selectedAllProjects = false;
      }
    }
    this.projects.forEach((element: any) => {
      if (element.stage === this.projectInstallation) {
        element.isSelectedProject = this.isSelectedAllInstallProjects;
      }
    });
    this.selectedInstallProjectCount = this.projects.filter((e: any) => e.isSelectedProject && e.stage === this.projectInstallation).length;
    this.checkAllProjectSelected();
  }
  selectInstallProjects(event: any, project: any) {
    project.isSelectedProject = event.target.checked;
    if (this.projects.filter((e: any) => e.stage === this.projectInstallation && !e.isSelectedProject).length > 0) {
      this.isSelectedAllInstallProjects = false;
    } else {
      this.isSelectedAllInstallProjects = true;
    }
    this.selectedInstallProjectCount = this.projects.filter((e: any) => e.isSelectedProject && e.stage === this.projectInstallation).length;
    this.checkAllProjectSelected();
  }
  selectDesignProjects(event: any, project: any) {
    project.isSelectedProject = event.target.checked;
    if (this.projects.filter((e: any) => e.stage === this.projectDesign && !e.isSelectedProject).length > 0) {
      this.isSelectedAllDesignProjects = false;
    } else {
      this.isSelectedAllDesignProjects = true;
    }
    this.selectedDesignProjectCount = this.projects.filter((e: any) => e.isSelectedProject && e.stage === this.projectDesign).length;
    this.checkAllProjectSelected();
  }
  checkAllProjectSelected() {
    if (this.projects.filter((e: any) => !e.isSelectedProject).length > 0) {
      this.selectedAllProjects = false;
    } else {
      this.selectedAllProjects = true;
    }
    this.selectedAllProjectsCount = this.projects.filter((e: any) => e.isSelectedProject).length;
  }
}

