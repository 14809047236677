<aside class="sidebar" [ngClass]="{'sidebar-expanded':expandsideBarClass,
    'global-sidebar': true }" *ngIf="hideLeftMenu">
    <a (click)="expandMenu()" class="btn-expand">
        <em class="far fa-angle-right"></em>
    </a>
    <ul class="help-link">
        <li (click)="saveVisitedMenu('global')" *ngIf="user?.user?.profileCompleted && user?.user?.role?.name ===
            roles.Administrator" [class.active]="getVisitedMenu() === 'global'">
            <a [routerLink]="['./global-manage']" href="javascript:void(0);">
                <em class="far fa-user-crown"></em>


                <ul class="submenu">
                    <li>
                        <a>Global Manage</a>
                    </li>
                </ul>
            </a>
        </li>
        <li (click)="saveVisitedMenu()" class="home" [ngClass]="{'active': helpSubmenu}">
            <a (click)="helpClick()"><em class="far fa-question-circle"></em>
                <ul class="submenu" [ngClass]="{'hoevermenuhide': helpSubmenu}">
                    <li>
                        <a>Help</a>
                    </li>
                </ul>
                <ul class="submenu-help" *ngIf="helpSubmenu" (click)="helpPopup()">
                    <li>
                        <div class="d-flex">
                            <a href="https://desk.zoho.com/portal/siteowl/kb" target="_blank">
                                <em class="icons fa-light fa-graduation-cap"></em>
                                <span>
                                    If you want to learn more about
                                    SiteOwl, access our <a>knowledge base.</a></span>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="d-flex">
                            <a href="https://desk.zoho.com/portal/siteowl/newticket" target="_blank"> <em
                                    class="icons far fa-tag"></em>
                                <span>
                                    If you have encountered an issue in SiteOwl,
                                    submit a
                                    <a> Ticket.</a></span>
                            </a>
                        </div>
                    </li>
                </ul>
            </a>
        </li>
    </ul>
</aside>

<aside class="sidebar" [ngClass]="{'sidebar-expanded':expandsideBarClass,
    'global-sidebar': !isCustomer && !isTechnician && !isTicketSubmitter }" *ngIf="!hideLeftMenu">
    <ul *ngIf="userDetails?.user?.profileCompleted">
        <li (click)="saveVisitedMenu('dashboard')" *ngIf="isCustomer|| isTechnician || isTicketSubmitter" class="home"
            [class.active]="getVisitedMenu() === 'dashboard'">
            <a [routerLink]="['./dashboard','customer']" href="javascript:void(0);">
                <em class="far fa-home"></em>

                <ul class="submenu">
                    <li>
                        <a>Dashboard</a>
                    </li>
                </ul>
            </a>
        </li>
        <li *ngIf="userDetails?.showProject && (userDetails?.showDesignProject || userDetails?.showInstallationProject) && (isCustomer || isTechnician) "
            class="sidebar-title"> <span> Projects </span>
        </li>
        <li (click)="saveVisitedMenu('design')" *ngIf="userDetails?.showProject && (isCustomer || isTechnician)"
            [class.active]="getVisitedMenu() === 'design'" class="project">
            <a [routerLink]="['./project/design']" [state]="{filter: 'projectReset'}" href="javascript:void(0);">
                <em class="far fa-pencil-ruler"></em>
                <ul class="submenu">
                    <li>
                        <a>Design Projects</a>
                    </li>
                </ul>
            </a>
        </li>

        <li (click)="saveVisitedMenu('install')" [class.active]="getVisitedMenu() === 'install'"
            *ngIf="userDetails?.showProject && userDetails?.showInstallationProject && (isCustomer || isTechnician)"
            class="project">
            <a [routerLink]="['./project/installation']" [state]="{filter: 'projectReset'}" href="javascript:void(0);">
                <em class="far fa-wrench"></em>

                <ul class="submenu long-text">
                    <li>
                        <a>Installation Projects</a>
                    </li>
                </ul>
            </a>
        </li>
        <li (click)="saveVisitedMenu('projectDevice');filterReset('PROJECTLISTFILTERS');" *ngIf="userDetails?.showProject &&
                    (isCustomer || isTechnician)" [class.active]="getVisitedMenu() === 'projectDevice'"
            class="device-project">
            <a [routerLink]="['./project','devices']" [queryParams]="{state:'all'}" [state]="{filter: 'projectReset'}">
                <em class="far fa-map-marker-alt"></em>

                <ul class="submenu">
                    <li>
                        <a>Project Devices</a>
                    </li>
                </ul>
            </a>
        </li>
        <li *ngIf="(isCustomer || isTechnician || isTicketSubmitter) && isLiveSystemAccessForCustomer"
            class="sidebar-title"><span>
                Manage</span>
        </li>
        <li (click)="saveVisitedMenu('planning')"
            *ngIf="(isAdministrator || isCustomerAdministator || isCustomerManager) && isLiveSystemAccessForCustomer"
            [class.active]="isLivePlanningActive()">
            <a [routerLink]="['./site/planning']" href="javascript:void(0);">
                <em class="fa-solid fa-chart-gantt"></em>
                <ul class="submenu">
                    <li>
                        <a>Live Planning</a>
                    </li>
                </ul>
            </a>
        </li>
        <li (click)="saveVisitedMenu('site')"
            *ngIf="(isCustomer|| isTechnician || isTicketSubmitter) && isLiveSystemAccessForCustomer"
            [class.active]="isLinkActive()">
            <a [routerLink]="['./live-site/list']" href="javascript:void(0);">
                <em class="far fa-layer-group"></em>
                <ul class="submenu">
                    <li>
                        <a>Live Sites</a>
                    </li>
                </ul>
            </a>
        </li>

        <li (click)="filterReset('TICKETLISTFILTERS');saveVisitedMenu('ticket')"
            [class.active]="getVisitedMenu() === 'ticket'"
            *ngIf="(isCustomer || isTechnician || isTicketSubmitter) && isTicketAccessible && isLiveSystemAccessForCustomer"
            [routerLinkActive]="['active']">
            <a [routerLink]="['./customer/ticket','list']" [state]="{filter: 'ticketReset'}">
                <em class="far fa-tag"></em>

                <ul class="submenu">
                    <li>
                        <a>Service Tickets</a>
                    </li>
                </ul>
            </a>
        </li>
        <li (click)="saveVisitedMenu('audit')"
            *ngIf="(isCustomer || isTechnician || isTicketSubmitter) && isLiveSystemAccessForCustomer"
            [class.active]="isAuditActive()">
            <a [routerLink]="['./audit','list']" href="javascript:void(0);">
                <em class="far fa-shield-check"></em>
                <ul class="submenu">
                    <li>
                        <a>Audits</a>
                    </li>
                </ul>
            </a>
        </li>
        <li (click)="saveVisitedMenu('siteDevice');filterReset('SITELISTFILTERS')"
            *ngIf="(isCustomer || isTechnician || isTicketSubmitter) && isLiveSystemAccessForCustomer"
            [class.active]="getVisitedMenu() === 'siteDevice'" class="devices">
            <a [routerLink]="['./live-site/devices']" [queryParams]="{state:'all'}" [state]="{filter: 'siteReset'}">
                <em class="far fa-map-marked-alt"></em>


                <ul class="submenu">
                    <li>
                        <a>Live Devices</a>
                    </li>
                </ul>
            </a>

        </li>
        <li *ngIf="(canAccessCustomerUsers || isAdministrator || isCustomerAdministator || isCustomerManager || isProjectManager) && (isCustomer || isProjectManager) "
            class="sidebar-title">
            <span>Configure</span>
        </li>
        <li (click)="saveVisitedMenu('library')" [class.active]="getVisitedMenu() === 'library'"
            *ngIf="(isAdministrator || isCustomerAdministator || isCustomerManager || isProjectManager) && (isCustomer || isProjectManager)">
            <a [routerLink]="['./live-site/library']" href="javascript:void(0);">
                <em class="far fa-map"></em>

                <ul class="submenu">
                    <li>
                        <a>Plan Library</a>
                    </li>
                </ul>
            </a>
        </li>

        <li (click)="saveVisitedMenu('setting')" *ngIf="isCustomer && canAccessCustomerUsers"
            [class.active]="getVisitedMenu() === 'setting'" class="user">
            <a [routerLink]="['./settings/customer-settings']">
                <em class="far fa-users-cog"></em>

                <ul class="submenu">
                    <li>
                        <a>Settings</a>
                    </li>
                </ul>
            </a>
        </li>
    </ul>
    <!-- for global dashboard tab navigation start -->
    <ul (click)="saveVisitedMenu()" *ngIf="userDetails.customerId === 0 && user?.user?.profileCompleted">
        <li *ngIf="providerId && isProviderAdminOrCoordinator" [routerLinkActive]="['active']">
            <a [routerLink]="['./dashboard', 'associated-customers']" href="javascript:void(0);">
                <em class="far fa-users"></em>
                <ul class="submenu">
                    <li>
                        <a>Accounts</a>
                    </li>
                </ul>
            </a>
        </li>
        <li (click)="saveVisitedMenu()" [routerLinkActive]="['active']">
            <a [routerLink]="['./dashboard', 'design']" href="javascript:void(0);">
                <em class="far fa-pencil-ruler"></em>

                <ul class="submenu">
                    <li>
                        <a>Design Projects</a>
                    </li>
                </ul>
            </a>
        </li>
        <li (click)="saveVisitedMenu()" [routerLinkActive]="['active']">
            <a [routerLink]="['./dashboard', 'install']" href="javascript:void(0);">
                <em class="far fa-wrench"></em>

                <ul class="submenu long-text">
                    <li>
                        <a>Installation Projects</a>
                    </li>
                </ul>
            </a>
        </li>
        <li (click)="saveVisitedMenu()" *ngIf="providerId && isProviderAdminOrCoordinator"
            [routerLinkActive]="['active']">
            <a [routerLink]="['./dashboard','activity']" href="javascript:void(0);">
                <em class="far fa-chart-bar"></em>
                <ul class="submenu long-text">
                    <li>
                        <a>Installation Activity</a>
                    </li>
                </ul>
            </a>
        </li>
        <li (click)="saveVisitedMenu()" [routerLinkActive]="['active']"
            *ngIf="isLiveSystemAccessForProvider === null || isLiveSystemAccessForProvider === true">
            <a [routerLink]="['./dashboard', 'service-ticket']" href="javascript:void(0);">
                <em class="far fa-headset"></em>

                <ul class="submenu">
                    <li>
                        <a>Service Tickets</a>
                    </li>
                </ul>
            </a>
        </li>
        <li (click)="saveVisitedMenu()" *ngIf="providerId && isProviderAdminOrCoordinator"
            [routerLinkActive]="['active']">
            <a [routerLink]="['./dashboard', 'integrator-users']" href="javascript:void(0);">
                <em class="far fa-people-carry"></em>
                <ul class="submenu">
                    <li>
                        <a *ngIf="!isUserProvider">Teams</a>
                        <a *ngIf="isUserProvider">Headquarters Users</a>
                    </li>
                </ul>
            </a>
        </li>
    </ul>
    <!-- for global dashboard tab navigation start -->
    <a (click)="expandMenu()" class="btn-expand">
        <em class="far fa-angle-right"></em>
    </a>
    <ul class="help-link">
        <li (click)="saveVisitedMenu('global')" *ngIf="user?.user?.profileCompleted && user?.user?.role?.name ===
            roles.Administrator" [class.active]="getVisitedMenu() === 'global'">
            <a [routerLink]="['./global-manage']" href="javascript:void(0);">
                <em class="far fa-user-crown"></em>


                <ul class="submenu">
                    <li>
                        <a>Global Manage</a>
                    </li>
                </ul>
            </a>
        </li>
        <li (click)="saveVisitedMenu()" class="home" [ngClass]="{'active': helpSubmenu}">
            <a (click)="helpClick()"><em class="far fa-question-circle"></em>
                <ul class="submenu" [ngClass]="{'hoevermenuhide': helpSubmenu}">
                    <li>
                        <a>Help</a>
                    </li>
                </ul>
                <ul class="submenu-help" *ngIf="helpSubmenu" (click)="helpPopup()">
                    <li>
                        <div class="d-flex">
                            <a href="https://desk.zoho.com/portal/siteowl/kb" target="_blank">
                                <em class="icons fa-light fa-graduation-cap"></em>
                                <span>
                                    If you want to learn more about
                                    SiteOwl, access our <a>knowledge base.</a></span>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="d-flex">
                            <a href="https://desk.zoho.com/portal/siteowl/newticket" target="_blank"> <em
                                    class="icons far fa-tag"></em>
                                <span>
                                    If you have encountered an issue in SiteOwl,
                                    submit a
                                    <a> Ticket.</a></span>
                            </a>
                        </div>
                    </li>
                </ul>
            </a>
        </li>
    </ul>
</aside>